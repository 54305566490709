(function ($) {
  "use strict";

  // DINAMYC FULL HEIGHT AND WIDTH BACKGROUND IMAGE ON WINDOW
  function setDimensions() {
    var windowsHeight = $(window).height();
    $(".fit-screen").css("height", windowsHeight + "px");
  }
  // when resizing the site, we adjust the heights of the sections
  $(window).resize(function () {
    setDimensions();
  });
  setDimensions();

  // DROPDOWN MENU
  $(document).ready(function () {
    $(".dropdown-menu a.dropdown-toggle").on("click", function (e) {
      var $el = $(this);
      var $parent = $(this).offsetParent(".dropdown-menu");
      if (!$(this).next().hasClass("show")) {
        $(this)
          .parents(".dropdown-menu")
          .first()
          .find(".show")
          .removeClass("show");
      }
      var $subMenu = $(this).next(".dropdown-menu");
      $subMenu.toggleClass("show");

      $(this).parent("li").toggleClass("show");

      $(this)
        .parents("li.nav-item.dropdown.show")
        .on("hidden.bs.dropdown", function (e) {
          $(".dropdown-menu .show").removeClass("show");
        });

      if (!$parent.parent().hasClass("navbar-nav")) {
        $el
          .next()
          .css({ top: $el[0].offsetTop, left: $parent.outerWidth() - 4 });
      }

      return false;
    });
  });

})(jQuery);
